import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Divider,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Autocomplete,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SaveAltRounded } from '@mui/icons-material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DataTable from '../components/InfluencerTable';
import SearchIcon from '@mui/icons-material/Search';
import { createInfluencer, createEstimate, getEstimateById, updateEstimate, getLatestRequestNumber, getContacts, createContact } from '../services/api';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { incrementRequestNumber } from '../utils/requestNumber';

interface Product {
  id: string;
  type: 'reels' | 'stories' | 'tiktok' | 'youtube' | 'combo_stories' | 'diaria';
  quantity: number;
  observation: string;
}

interface ScopeItem {
  id: string;
  title: string;
  influencer: {
    id: string;
    name: string;
  };
  products: Product[];
  specifications: {
    hasPartnershipLabel: boolean;
    hasProduction: boolean;
    hasRepostRights: boolean;
    hasBoost: boolean;
    boostDuration?: number;
    hasExclusivity: boolean;
    hasImageRights: boolean;
    campaignDurationType: 'days' | 'months' | 'years';
    additionalNotes?: string;
    hasCampaignDuration: boolean;
    campaignDuration?: number;
  };
}

interface Specifications {
  hasPartnershipLabel: boolean;
  hasProduction: boolean;
  hasRepostRights: boolean;
  hasBoost: boolean;
  boostDuration?: number;
  hasExclusivity: boolean;
  hasImageRights: boolean;
  campaignDurationType: 'days' | 'months' | 'years';
  additionalNotes?: string;
  hasCampaignDuration: boolean;
  campaignDuration?: number;
}

interface InfluencerScope {
  scopeId: string;
  value: number;
}

interface EstimateInfluencer {
  id: string;
  name: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
  scopeValues: InfluencerScope[];
}

interface Contact {
  id: string;
  name: string;
  brand: string;
  email: string;
  phone: string;
}

const PRODUCT_TYPES = [
  { value: 'reels', label: 'Reels' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'stories', label: 'Stories' },
  { value: 'combo_stories', label: 'Combo Story' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'diaria', label: 'Diária' }
];

// Add this utility function near the top of the file, after the imports
const formatToBRL = (value: number): string => {
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const parseBRLString = (value: string): number => {
  return Number(value.replace(/\D/g, '')) / 100;
};

const formatPhone = (value: string): string => {
  // Remove all non-digit characters
  const numbers = value.replace(/\D/g, '');
  
  // Format according to the length
  if (numbers.length <= 2) {
    return numbers;
  } else if (numbers.length <= 7) {
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
  } else if (numbers.length <= 11) {
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7)}`;
  }
  
  // Limit to 11 digits and format
  const limited = numbers.slice(0, 11);
  return `(${limited.slice(0, 2)}) ${limited.slice(2, 7)}-${limited.slice(7)}`;
};

const NewEstimate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isEditMode] = useState(!!id);
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [showYoutubeLinks, setShowYoutubeLinks] = useState(false);
  
  // Create initial scope
  const createInitialScope = () => ({
    id: crypto.randomUUID(),
    title: 'Escopo 1',
    influencer: { id: '', name: '' },
    products: [],
    specifications: {
      hasPartnershipLabel: false,
      hasProduction: false,
      hasRepostRights: false,
      hasBoost: false,
      hasExclusivity: false,
      hasImageRights: false,
      campaignDurationType: 'days',
      hasCampaignDuration: false,
      campaignDuration: undefined,
    },
  });

  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    email: '',
    phone: '',
    campaignDesc: '',
    requestNumber: 'OR-001',
    scopes: [createInitialScope()] as ScopeItem[], // Initialize with one scope
    influencers: [] as EstimateInfluencer[]
  });
  const [scopeOptions, setScopeOptions] = useState<Array<{name: string, value: string}>>([
    { name: 'Combo de Story', value: 'service1' },
    { name: 'Story', value: 'service2' },
    { name: 'TikTok', value: 'service3' },
    { name: 'Reels', value: 'service4' },
    { name: 'Inserção de Youtube', value: 'service5' }
  ]);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [customInfluencerForm, setCustomInfluencerForm] = useState({
    id: '',
    data: new Date().toISOString(),
    nome: '',
    segmento: [] as string[],
    instagram: '',
    youtube: '',
    tiktok: '',
    base_insta: 0,
    base_tiktok: 0,
    base_youtube: 0,
    value_combo_stories: 0,
    value_reels: 0,
    value_insert_youtube: 0,
    value_tiktok: 0,
    cidade: '',
    estado: '',
    aposta: false,
    contato: '',
    email: '',
    agencia: '',
    mini_bio: '',
    status: ''
  });
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState<EstimateInfluencer | null>(null);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [selectedInfluencers, setSelectedInfluencers] = useState<Array<any>>([]);
  const [collapsedScopes, setCollapsedScopes] = useState<Set<string>>(new Set());
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [isNewContact, setIsNewContact] = useState(false);

  useEffect(() => {
    const initializeForm = async () => {
      if (id) {
        try {
          const data = await getEstimateById(id);
          setFormData({
            name: data.name,
            contact: data.contact,
            email: data.email,
            phone: data.phone,
            campaignDesc: data.campaignDesc,
            requestNumber: data.requestNumber,
            scopes: data.scopes,
            influencers: data.influencers
          });

          // Find matching contact from contacts list
          const matchingContact = contacts.find(contact => 
            contact.name === data.contact && 
            contact.email === data.email && 
            contact.phone === data.phone
          );

          if (matchingContact) {
            setSelectedContact(matchingContact);
            setIsNewContact(false);
          } else {
            // If no matching contact is found, treat as new contact
            setSelectedContact(data.contact);
            setIsNewContact(true);
          }
        } catch (err) {
          setAlertMessage('Failed to fetch estimate data');
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      } else {
        // Get latest request number for new estimate
        try {
          const latestNumber = await getLatestRequestNumber();
          setFormData(prev => ({
            ...prev,
            requestNumber: latestNumber
          }));
        } catch (err) {
          console.error('Error setting request number:', err);
        }
      }
    };

    initializeForm();
  }, [id, contacts]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await getContacts();
        setContacts(response || []); // Ensure we set an empty array if response is null/undefined
      } catch (error) {
        console.error('Error fetching contacts:', error);
        setContacts([]); // Set empty array on error
        setAlertMessage('Failed to fetch contacts');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    fetchContacts();
  }, []);

  // Modify handleOpen to not clear selections
  const handleOpen = () => {
    setOpen(true);
  };

  // Update handleClose to only clear selections when influencers are added
  const handleClose = (clearSelections: boolean = false) => {
    setOpen(false);
    setCurrentRow(null);
    if (clearSelections) {
      setSelectedInfluencers([]);
    }
  };

  // Handle selecting an influencer from the modal
  const handleSelectInfluencer = (selectedInfluencer: { 
    id: string;
    name: string;
    instagram?: string;
    youtube?: string;
    tiktok?: string;
  }) => {
    const newInfluencer: EstimateInfluencer = {
      id: selectedInfluencer.id,
      name: selectedInfluencer.name,
      instagram: selectedInfluencer.instagram,
      youtube: selectedInfluencer.youtube,
      tiktok: selectedInfluencer.tiktok,
      scopeValues: formData.scopes.map(scope => ({
        scopeId: scope.id,
        value: 0
      }))
    };

    setFormData(prev => ({
      ...prev,
      influencers: [...prev.influencers, newInfluencer]
    }));
    
    handleClose();
  };

  const handleAddProduct = (scopeIndex: number) => {
    const updatedScopes = [...formData.scopes];
    updatedScopes[scopeIndex].products.push({
      id: crypto.randomUUID(),
      type: 'reels',
      quantity: 1,
      observation: '',
    });
    setFormData({ ...formData, scopes: updatedScopes });
  };

  const handleProductChange = <T extends keyof Product>(
    scopeIndex: number,
    productIndex: number,
    field: T,
    value: Product[T]
  ) => {
    const updatedScopes = [...formData.scopes];
    updatedScopes[scopeIndex].products[productIndex][field] = value;
    
    setFormData({ ...formData, scopes: updatedScopes });
  };

  const handleRemoveProduct = (scopeIndex: number, productIndex: number) => {
    const updatedScopes = [...formData.scopes];
    updatedScopes[scopeIndex].products.splice(productIndex, 1);
    setFormData({ ...formData, scopes: updatedScopes });
  };

  const handleSpecificationChange = <T extends keyof Specifications>(
    scopeIndex: number,
    field: T,
    value: Specifications[T]
  ) => {
    const updatedScopes = [...formData.scopes];
    updatedScopes[scopeIndex].specifications[field] = value;
    setFormData({ ...formData, scopes: updatedScopes });
  };

  const handleAddScope = () => {
    const newScopeId = crypto.randomUUID();
    const newScope: ScopeItem = {
      id: newScopeId,
      title: `Escopo ${formData.scopes.length + 1}`,
      influencer: { id: '', name: '' },
      products: [],
      specifications: {
        hasPartnershipLabel: false,
        hasProduction: false,
        hasRepostRights: false,
        hasBoost: false,
        hasExclusivity: false,
        hasImageRights: false,
        campaignDurationType: 'days',
        hasCampaignDuration: false,
        campaignDuration: undefined,
      },
    };

    // Add the new scope to all existing influencers' scopeValues
    const updatedInfluencers = formData.influencers.map(inf => ({
      ...inf,
      scopeValues: [...inf.scopeValues, { scopeId: newScopeId, value: 0 }]
    }));

    setFormData(prev => ({
      ...prev,
      scopes: [...prev.scopes, newScope],
      influencers: updatedInfluencers
    }));
  };


  const handleRemoveScope = (scopeIndex: number) => {
    const updatedScopes = [...formData.scopes];
    updatedScopes.splice(scopeIndex, 1);
    setFormData({ ...formData, scopes: updatedScopes });
  };

  // Add handler for influencer value changes
  const handleInfluencerValueChange = (influencerIndex: number, scopeId: string, value: number) => {
    const updatedInfluencers = [...formData.influencers];
    const scopeValueIndex = updatedInfluencers[influencerIndex].scopeValues.findIndex(
      sv => sv.scopeId === scopeId
    );
    
    if (scopeValueIndex !== -1) {
      updatedInfluencers[influencerIndex].scopeValues[scopeValueIndex].value = value;
      setFormData({ ...formData, influencers: updatedInfluencers });
    }
  };

  // Add handler for removing influencer
  const handleRemoveInfluencer = (influencerIndex: number) => {
    const updatedInfluencers = [...formData.influencers];
    updatedInfluencers.splice(influencerIndex, 1);
    setFormData({ ...formData, influencers: updatedInfluencers });
  };

  const handleOpenDetails = (influencer: EstimateInfluencer) => {
    setSelectedInfluencer(influencer);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setSelectedInfluencer(null);
    setDetailsOpen(false);
  };

  // Add this new handler function near the other handlers
  const handleUnitValueChange = (
    influencerId: string,
    scopeId: string,
    productId: string,
    newUnitValue: number
  ) => {
    setFormData(prev => {
      const updatedInfluencers = [...prev.influencers];
      const influencerIndex = updatedInfluencers.findIndex(inf => inf.id === influencerId);
      
      // Return early if influencer not found
      if (influencerIndex === -1) return prev;

      const scope = prev.scopes.find(s => s.id === scopeId);
      // Return early if scope not found
      if (!scope) return prev;

      // Ensure products array exists and has items
      const totalProducts = scope.products?.reduce((sum, p: Product) => sum + p.quantity, 0) || 0;
      // Prevent division by zero
      if (totalProducts === 0) return prev;

      const newTotalValue = newUnitValue * totalProducts;

      // Find and update the scope value safely
      const scopeValueIndex = updatedInfluencers[influencerIndex].scopeValues?.findIndex(
        sv => sv.scopeId === scopeId
      );
      
      if (scopeValueIndex !== -1) {
        updatedInfluencers[influencerIndex].scopeValues[scopeValueIndex].value = newTotalValue;
      }

      return { ...prev, influencers: updatedInfluencers };
    });
  };

  const formatEstimateSummary = () => {
    const formatCurrency = (value: number) => 
      `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;

    let summary = ``;
    summary += `${'-'.repeat(50)}\n\n`;
    
    summary += `CLIENTE: ${formData.contact && formData.name ? `${formData.contact} (${formData.name})` : formData.contact}\n`;
    summary += `Email: ${formData.email}\n`;
    summary += `Telefone: ${formData.phone}\n\n`;

    if (formData.campaignDesc) {
      summary += `DESCRIÇÃO DA CAMPANHA:\n${formData.campaignDesc}\n\n`;
    }

    // Influencers Section
    summary += `INFLUENCIADORES:\n`;
    summary += `${'-'.repeat(50)}\n`;
    formData.influencers.forEach(influencer => {
      summary += `\n► ${influencer.name.toUpperCase()}\n`;
      summary += `${influencer.instagram ? `${influencer.instagram}\n` : ''}`;
      summary += `${influencer.tiktok ? `${influencer.tiktok}\n` : ''}`;
      // Only show YouTube links if enabled
      summary += `${showYoutubeLinks && influencer.youtube ? `${influencer.youtube}\n` : ''}`;
      
      // Show scopes for this influencer
      formData.scopes.forEach(scope => {
        const scopeValue = influencer.scopeValues?.find(sv => sv.scopeId === scope.id)?.value || 0;
        if (scopeValue > 0) {
          summary += `\n${scope.title}:\n`;
          
          // Products section
          summary += `\n`;
          scope.products.forEach(product => {
            const productType = PRODUCT_TYPES?.find(t => t.value === product.type)?.label;
            summary += `• ${product.quantity} ${productType}${product.observation ? ` (${product.observation})` : ''}\n`;
          });
          
          // Specifications section
          summary += `\n`;
          if (scope.specifications.hasPartnershipLabel) {
            summary += ` • Rótulo de parceria paga\n`;
          }
          if (scope.specifications.hasProduction) {
            summary += ` • Produção\n`;
          }
          if (scope.specifications.hasRepostRights) {
            summary += ` • Direitos de repost\n`;
          }
          if (scope.specifications.hasBoost) {
            summary += ` • Impulsionamento ${scope.specifications.boostDuration} dias\n`;
          }
          if (scope.specifications.hasExclusivity) {
            summary += ` • Com Exclusividade\n`;
          } else {
            summary += ` • Sem Exclusividade\n`;
          }
          if (scope.specifications.hasCampaignDuration) {
            summary += ` • Duração da campanha: ${scope.specifications.campaignDuration} dias\n`;
          }
          if (scope.specifications.hasImageRights) {
            summary += ` • Uso de Imagem\n`;
          }
          
          if (scope.specifications.additionalNotes) {
            summary += `\nObservações:\n${scope.specifications.additionalNotes}\n`;
          }

          summary += `\nValor: ${formatCurrency(scopeValue)}\n`;
        }
      });
      summary += `\n${'-'.repeat(50)}\n`;
    });



    return summary;
  };

  const handleSave = async () => {
    try {
      // If it's a new contact, create it first
      if (isNewContact) {
        const contactData = {
          name: formData.contact,
          brand: formData.name,
          email: formData.email,
          phone: formData.phone
        };
        await createContact(contactData);
      }

      const estimateData = {
        requestNumber: formData.requestNumber,
        name: formData.name,
        contact: formData.contact,
        email: formData.email,
        phone: formData.phone,
        campaignDesc: formData.campaignDesc,
        scopes: formData.scopes.map(scope => ({
          id: scope.id,
          title: scope.title,
          products: scope.products,
          specifications: scope.specifications
        })),
        influencers: formData.influencers
      };

      if (isEditMode) {
        await updateEstimate(id!, estimateData);
        setAlertMessage('Orçamento atualizado com sucesso!');
      } else {
        await createEstimate(estimateData);
        setAlertMessage('Orçamento criado com sucesso!');
      }
      
      setAlertSeverity('success');
      setAlertOpen(true);
      navigate('/estimates');
    } catch (error) {
      console.error('Error saving:', error);
      setAlertMessage(isEditMode ? 'Erro ao atualizar orçamento' : 'Erro ao criar orçamento');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const toggleScopeCollapse = (scopeId: string) => {
    setCollapsedScopes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(scopeId)) {
        newSet.delete(scopeId);
      } else {
        newSet.add(scopeId);
      }
      return newSet;
    });
  };

  // Add this new function to check if an influencer is already added
  const isInfluencerAdded = (influencerId: string) => {
    return formData.influencers.some(inf => inf.id === influencerId);
  };

  // Add this function to get the next scope number
  const getNextScopeNumber = () => {
    const numbers = formData.scopes.map(scope => {
      const match = scope.title.match(/Escopo (\d+)/);
      return match ? parseInt(match[1]) : 0;
    });
    return Math.max(...numbers, 0) + 1;
  };

  // Update the handleCopyScope function
  const handleCopyScope = (scopeIndex: number) => {
    const scopeToCopy = formData.scopes[scopeIndex];
    const newScopeId = crypto.randomUUID();
    const nextNumber = getNextScopeNumber();
    
    // Create deep copy of the scope with new ID and incremented number
    const newScope: ScopeItem = {
      ...scopeToCopy,
      id: newScopeId,
      title: `Escopo ${nextNumber}`,
      products: scopeToCopy.products.map(product => ({
        ...product,
        id: crypto.randomUUID()
      }))
    };

    // Add the new scope to all existing influencers' scopeValues
    const updatedInfluencers = formData.influencers.map(inf => ({
      ...inf,
      scopeValues: [
        ...inf.scopeValues,
        {
          scopeId: newScopeId,
          value: inf.scopeValues.find(sv => sv.scopeId === scopeToCopy.id)?.value || 0
        }
      ]
    }));

    setFormData(prev => ({
      ...prev,
      scopes: [...prev.scopes, newScope],
      influencers: updatedInfluencers
    }));
  };

  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Button
          component={Link}
          to="/estimates"
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
          sx={{ marginRight: 2 }}
        >
          Orçamentos
        </Button>
        <h1>{isEditMode ? 'Editar Orçamento' : 'Criar Orçamento'}</h1>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveAltRounded />}
          onClick={handleSave}
          sx={{ marginRight: 2 }}
        >
          Salvar
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField 
            fullWidth 
            label="Number" 
            name="requestNumber" 
            value={formData.requestNumber} 
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={contacts}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.name;
              }}
              value={selectedContact}
              onChange={(_, newValue) => {
                if (!newValue) {
                  // Handle clearing the field
                  setIsNewContact(true);
                  setSelectedContact(null);
                  setFormData(prev => ({
                    ...prev,
                    contact: '',
                    name: '',
                    email: '',
                    phone: ''
                  }));
                } else if (typeof newValue === 'string') {
                  // Handle typing a new value
                  setIsNewContact(true);
                  setSelectedContact(null);
                  setFormData(prev => ({
                    ...prev,
                    contact: newValue,
                    name: '',
                    email: '',
                    phone: ''
                  }));
                } else {
                  // Handle selecting an existing contact
                  setSelectedContact(newValue);
                  setIsNewContact(false);
                  setFormData(prev => ({
                    ...prev,
                    contact: newValue.name,
                    name: newValue.brand,
                    email: newValue.email,
                    phone: newValue.phone
                  }));
                }
              }}
              onInputChange={(_, newInputValue, reason) => {
                if (reason === 'input') {
                  // When user is typing, switch to new contact mode
                  setIsNewContact(true);
                  setSelectedContact(null);
                  setFormData(prev => ({
                    ...prev,
                    contact: newInputValue,
                    name: '',
                    email: '',
                    phone: ''
                  }));
                } else if (reason === 'clear') {
                  // When user clears the input
                  setIsNewContact(true);
                  setSelectedContact(null);
                  setFormData(prev => ({
                    ...prev,
                    contact: '',
                    name: '',
                    email: '',
                    phone: ''
                  }));
                }
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={isNewContact ? "Nome do Contato" : "Selecionar Contato"}
                  placeholder="Digite ou escolha um contato existente"
                />
              )}
            />
          </Grid>
        

        {/* Contact Fields - shown for both new and existing contacts */}
        <>
          <Grid item xs={12} md={4}>
            <TextField 
              fullWidth 
              label="Marca" 
              value={formData.name}
              onChange={(e) => {
                setIsNewContact(true);
                setSelectedContact(null);
                setFormData({...formData, name: e.target.value});
              }}
              placeholder="Nome da marca" 
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField 
              fullWidth 
              label="Email" 
              name="email" 
              type="email"
              value={formData.email} 
              onChange={(e) => {
                setIsNewContact(true);
                setSelectedContact(null);
                setFormData({...formData, email: e.target.value});
              }} 
              placeholder="email@exemplo.com" 
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField 
              fullWidth 
              label="Telefone" 
              name="phone" 
              value={formData.phone} 
              onChange={(e) => {
                setIsNewContact(true);
                setSelectedContact(null);
                const formatted = formatPhone(e.target.value);
                setFormData({...formData, phone: formatted});
              }} 
              placeholder="(00) 00000-0000"
              inputProps={{
                maxLength: 15
              }}
            />
          </Grid>
        </>
       
        <Grid item xs={12} md={12}>
          <TextField 
            label="Descrição da Campanha" 
            name="campaignDesc"
            value={formData.campaignDesc}
            onChange={(e) => setFormData({...formData, campaignDesc: e.target.value})}
            multiline 
            rows={4} 
            variant="outlined" 
            fullWidth 
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ marginVertical: '20px' }} />
         
        </Grid>
      </Grid>



      {/* Scopes Section */}
      {formData.scopes.map((scope, scopeIndex) => (
        <Paper key={scope.id} sx={{ p: 3, mb: 3 }}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6">
              {scope.title}
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleScopeCollapse(scope.id)}
                sx={{ mr: 1 }}
              >
                {collapsedScopes.has(scope.id) ? 'Expandir' : 'Salvar Escopo'}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleCopyScope(scopeIndex)}
                sx={{ mr: 1 }}
              >
                Copiar Escopo
              </Button>
              <IconButton onClick={() => handleRemoveScope(scopeIndex)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Only show content if scope is not collapsed */}
          {!collapsedScopes.has(scope.id) && (
            <>
              {/* Products Section */}
              <Box mb={3}>
                <Typography variant="subtitle1" mb={2}>Produtos</Typography>
                {scope.products.map((product, productIndex) => (
                  <Grid container spacing={2} key={product.id} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        select
                        fullWidth
                        label="Type"
                        value={product.type}
                        onChange={(e) => handleProductChange(scopeIndex, productIndex, 'type', e.target.value as Product['type'])}
                      >
                        {PRODUCT_TYPES.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Quantidade"
                        value={product.quantity}
                        onChange={(e) => handleProductChange(scopeIndex, productIndex, 'quantity', parseInt(e.target.value) as Product['quantity'])}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Observação"
                        value={product.observation}
                        onChange={(e) => handleProductChange(scopeIndex, productIndex, 'observation', e.target.value as Product['observation'])}
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <IconButton 
                        onClick={() => handleRemoveProduct(scopeIndex, productIndex)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleAddProduct(scopeIndex)}
                  sx={{ mt: 1 }}
                >
                  Adicionar Produto
                </Button>
              </Box>

              {/* Specifications Section */}
              <Box mb={3}>
                <Typography variant="subtitle1" mb={2}>Especificações</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scope.specifications.hasPartnershipLabel}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'hasPartnershipLabel', e.target.checked)}
                        />
                      }
                      label="Rótulo de parceria paga"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scope.specifications.hasProduction}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'hasProduction', e.target.checked)}
                        />
                      }
                      label="Produção"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scope.specifications.hasRepostRights}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'hasRepostRights', e.target.checked)}
                        />
                      }
                      label="Direitos de repost"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scope.specifications.hasBoost}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'hasBoost', e.target.checked)}
                        />
                      }
                      label="Impulsionamento"
                    />
                    {scope.specifications.hasBoost && (
                      <TextField
                        size="small"
                        type="number"
                        label="Duração do Impulsionamento (dias)"
                        value={scope.specifications.boostDuration || ''}
                        onChange={(e) => handleSpecificationChange(scopeIndex, 'boostDuration', Number(e.target.value))}
                        sx={{ ml: 2 }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scope.specifications.hasExclusivity}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'hasExclusivity', e.target.checked)}
                        />
                      }
                      label="Exclusividade"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scope.specifications.hasCampaignDuration}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'hasCampaignDuration', e.target.checked)}
                        />
                      }
                      label="Duração da Campanha"
                    />
                    {scope.specifications.hasCampaignDuration && (
                      <Box display="flex" alignItems="center" gap={2} ml={2}>
                        <TextField
                          size="small"
                          type="number"
                          label="Duração"
                          value={scope.specifications.campaignDuration || ''}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'campaignDuration', Number(e.target.value))}
                        />
                        <TextField
                          select
                          size="small"
                          value={scope.specifications.campaignDurationType || 'days'}
                          onChange={(e) => handleSpecificationChange(
                            scopeIndex, 
                            'campaignDurationType', 
                            e.target.value as 'days' | 'months' | 'years'
                          )}
                          sx={{ minWidth: 120 }}
                        >
                          <MenuItem value="days">Dias</MenuItem>
                          <MenuItem value="months">Meses</MenuItem>
                          <MenuItem value="years">Anos</MenuItem>
                        </TextField>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scope.specifications.hasImageRights}
                          onChange={(e) => handleSpecificationChange(scopeIndex, 'hasImageRights', e.target.checked)}
                        />
                      }
                      label="Uso de Imagem"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      label="Observações Adicionais"
                      value={scope.specifications.additionalNotes || ''}
                      onChange={(e) => handleSpecificationChange(scopeIndex, 'additionalNotes', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Paper>
      ))}

<Button
        variant="contained"
        onClick={handleAddScope}
        sx={{ mb: 3 }}
      >
        Adicionar Escopo
      </Button>

      {/* Influencers Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Influenciadores</Typography>
          <Button
            variant="contained"
            onClick={handleOpen}
            startIcon={<SearchIcon />}
          >
            Adicionar Influenciador
          </Button>
        </Box>

        {formData.influencers.length > 0 && (
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Influenciador</TableCell>
                  {formData.scopes.map((scope) => (
                    <TableCell key={scope.id}>{scope.title}</TableCell>
                  ))}
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.influencers.map((influencer, index) => (
                  <TableRow key={influencer.id}>
                    <TableCell>{influencer.name}</TableCell>
                    {formData.scopes.map((scope) => (
                      <TableCell key={scope.id}>
                        <TextField
                          size="small"
                          value={formatToBRL(influencer.scopeValues?.find(sv => sv.scopeId === scope.id)?.value || 0)}
                          onChange={(e) => {
                            const rawValue = e.target.value.replace(/\D/g, '');
                            const numberValue = Number(rawValue) / 100;
                            handleInfluencerValueChange(index, scope.id, numberValue);
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                        />
                      </TableCell>
                    ))}
                    <TableCell>
                      <Box display="flex" gap={1}>
                        <IconButton
                        color='primary'
                          onClick={() => handleOpenDetails(influencer)}
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleRemoveInfluencer(index)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      
      {/* Influencer Selection Modal */}
      <Dialog 
        open={open} 
        onClose={(event, reason) => handleClose()} 
        fullScreen
      >
        <DialogTitle>
          {showCustomForm ? 'Criar Novo Influenciador' : 'Selecionar Influenciadores'}
        </DialogTitle>
        <Divider sx={{margin:0 }}/>
        <DialogContent>
          {showCustomForm ? (
            <Box component="form" sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    value={customInfluencerForm.nome}
                    onChange={(e) => setCustomInfluencerForm(prev => ({
                      ...prev,
                      nome: e.target.value
                    }))}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={customInfluencerForm.email}
                    onChange={(e) => setCustomInfluencerForm(prev => ({
                      ...prev,
                      email: e.target.value
                    }))}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Instagram"
                    value={customInfluencerForm.instagram}
                    onChange={(e) => setCustomInfluencerForm(prev => ({
                      ...prev,
                      instagram: e.target.value
                    }))}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Youtube"
                    value={customInfluencerForm.youtube}
                    onChange={(e) => setCustomInfluencerForm(prev => ({
                      ...prev,
                      youtube: e.target.value
                    }))}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="TikTok"
                    value={customInfluencerForm.tiktok}
                    onChange={(e) => setCustomInfluencerForm(prev => ({
                      ...prev,
                      tiktok: e.target.value
                    }))}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Contato"
                    value={customInfluencerForm.contato}
                    onChange={(e) => setCustomInfluencerForm(prev => ({
                      ...prev,
                      contato: e.target.value
                    }))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customInfluencerForm.aposta}
                        onChange={(e) => setCustomInfluencerForm(prev => ({
                          ...prev,
                          aposta: e.target.checked
                        }))}
                      />
                    }
                    label="Aposta"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <DataTable
              onSelectInfluencers={(selected) => setSelectedInfluencers(selected)}
              selectedInfluencers={selectedInfluencers}
              isInfluencerAdded={isInfluencerAdded}
              selectionMode={true}
            />
          )}
        </DialogContent>
        <DialogActions>
          {showCustomForm ? (
            <>
              <Button onClick={() => setShowCustomForm(false)}>
                Voltar
              </Button>
              <Button 
                variant="contained" 
                color="primary"
                onClick={async () => {
                  try {
                    const newInfluencerData = await createInfluencer(customInfluencerForm);
                    const newInfluencer = {
                      id: newInfluencerData.id,
                      name: newInfluencerData.nome
                    };
                    handleSelectInfluencer(newInfluencer);
                    setShowCustomForm(false);
                  } catch (error) {
                    console.error('Error creating influencer:', error);
                    // You might want to show an error message to the user
                  }
                }}
              >
                Salvar
              </Button>
            </>
          ) : (
            <>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => setShowCustomForm(true)}
              >
                Criar Influenciador
              </Button>
              {selectedInfluencers?.length > 0 && (
                <Button 
                  variant="contained" 
                  color="secondary"
                  onClick={() => {
                    selectedInfluencers.forEach(influencer => handleSelectInfluencer(influencer));
                    handleClose(true);
                  }}
                >
                  Selecionar Influenciadores ({selectedInfluencers.length})
                </Button>
              )}
              <Button onClick={() => handleClose(false)}>Fechar</Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={detailsOpen}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Detalhes do Influenciador: {selectedInfluencer?.name}
        </DialogTitle>
        <DialogContent>
          {selectedInfluencer && formData.scopes.map((scope) => {
            const scopeValue = selectedInfluencer.scopeValues?.find(
              sv => sv.scopeId === scope.id
            )?.value || 0;

            return (
              <Box key={scope.id} sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  {scope.title}
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Produto</TableCell>
                        <TableCell>Quantidade</TableCell>
                        <TableCell>Observação</TableCell>
                        <TableCell>Valor Unitário</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scope.products.map((product : Product) => {
                        const unitValue = scopeValue / scope.products.reduce((sum, p: Product) => sum + p.quantity, 0);
                        
                        return (
                          <TableRow key={product.id}>
                            <TableCell>{PRODUCT_TYPES.find(t => t.value === product.type)?.label}</TableCell>
                            <TableCell>{product.quantity}</TableCell>
                            <TableCell>{product.observation}</TableCell>
                            <TableCell>
                              <TextField
                                type="number"
                                size="small"
                                value={unitValue}
                                onChange={(e) => handleUnitValueChange(
                                  selectedInfluencer.id,
                                  scope.id,
                                  product.id,
                                  Number(e.target.value)
                                )}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Valor Total: R$ {scopeValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Fechar</Button>
        </DialogActions>
      </Dialog>

      {/* Add action buttons at the bottom */}
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 3, mb: 4 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveAltRounded />}
          onClick={handleSave}
        >
          Salvar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setSummaryOpen(true)}
        >
          Abrir Resumo de Orçamento
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={async () => {
            // First save the estimate
            await handleSave();
            
            // Then open WhatsApp with the estimate summary
            const text = encodeURIComponent(formatEstimateSummary());
            const phoneNumber = formData.phone.replace(/\D/g, ''); // Remove non-digits
            const whatsappUrl = `https://wa.me/55${phoneNumber}?text=${text}`;
            window.open(whatsappUrl, '_blank');
          }}
          disabled={!formData.phone} // Disable if no phone number
        >
          Enviar via WhatsApp
        </Button>
      </Box>

      <Dialog
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Resumo do Orçamento
          <Box sx={{ float: 'right', display: 'flex', gap: 1, alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showYoutubeLinks}
                  onChange={(e) => setShowYoutubeLinks(e.target.checked)}
                  size="small"
                />
              }
              label="Mostrar Links do YouTube"
            />
            <IconButton
              aria-label="copy"
              onClick={() => {
                navigator.clipboard.writeText(formatEstimateSummary());
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            component="pre"
            sx={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              fontSize: '0.9rem',
              p: 2,
              backgroundColor: '#f5f5f5',
              borderRadius: 1,
            }}
          >
            {formatEstimateSummary()}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSummaryOpen(false)}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={alertOpen} 
        autoHideDuration={6000} 
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setAlertOpen(false)} 
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default NewEstimate;
