import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import BusinessIcon from '@mui/icons-material/Business';

const SidebarMenu: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>('Home');
  const location = useLocation();

  useEffect(() => {
    // Map paths to menu items
    const pathToItem: { [key: string]: string } = {
      '/': 'Home',
      '/estimates': 'Orçamentos',
      '/new-estimate': 'Orçamentos',
      '/brands': 'Brands',
      '/contacts': 'Contatos'
    };
    
    // Set active item based on current path
    const currentItem = pathToItem[location.pathname] || 'Home';
    setActiveItem(currentItem);
  }, [location.pathname]);

  const handleMenuItemClick = (item: string) => {
    setActiveItem(item);
  };

  return (
    <Box sx={{ overflow: 'auto', flexGrow: 1, color: 'primary.dark', height: '100vh' }}>
      <Typography variant="h6" sx={{ padding: '16px', color: '#888' }}>
        Dashboards
      </Typography>
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          onClick={() => handleMenuItemClick('Home')}
          sx={{
            backgroundColor: activeItem === 'Home' ? 'primary.light' : 'transparent',
            borderRadius: '8px',
            color: 'primary.dark',
            margin: '8px',
            '&:hover': {
              backgroundColor: 'primary.light',
            },
          }}
        >
          <ListItemIcon>
            <HomeIcon sx={{ color: activeItem === 'Home' ? 'primary.dark' : '#888' }} />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ color: activeItem === 'Home' ? 'primary.dark' : '#888' }} />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/estimates"
          onClick={() => handleMenuItemClick('Orçamentos')}
          sx={{
            backgroundColor: activeItem === 'Orçamentos' ? 'primary.light' : 'transparent',
            borderRadius: '8px',
            color: 'primary.dark',
            margin: '8px',
            '&:hover': {
              backgroundColor: 'primary.light',
            },
          }}
        >
          <ListItemIcon>
            <ShoppingCartIcon sx={{ color: activeItem === 'Orçamentos' ? 'primary.dark' : '#888' }} />
          </ListItemIcon>
          <ListItemText primary="Orçamentos" sx={{ color: activeItem === 'Orçamentos' ? 'primary.dark' : '#888' }} />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/contacts"
          onClick={() => handleMenuItemClick('Contatos')}
          sx={{
            backgroundColor: activeItem === 'Contatos' ? 'primary.light' : 'transparent',
            borderRadius: '8px',
            color: 'primary.dark',
            margin: '8px',
            '&:hover': {
              backgroundColor: 'primary.light',
            },
          }}
        >
          <ListItemIcon>
            <BusinessIcon sx={{ color: activeItem === 'Contatos' ? 'primary.dark' : '#888' }} />
          </ListItemIcon>
          <ListItemText primary="Contatos" sx={{ color: activeItem === 'Contatos' ? 'primary.dark' : '#888' }} />
        </ListItem>
      </List>
    </Box>
  );
};

export default SidebarMenu;
