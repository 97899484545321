import React, { useState, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import { ThemeProvider, CssBaseline, Box, CircularProgress } from '@mui/material';
import theme from './theme';
import keycloak from './services/keycloak';

const KEYCLOAK_ENABLED = process.env.REACT_APP_KEYCLOAK_ENABLED === 'true';

const App: React.FC = () => {
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);

  useEffect(() => {
    if (KEYCLOAK_ENABLED) {
      keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        pkceMethod: 'S256'
      }).then((authenticated) => {
        if (authenticated) {
          setKeycloakInitialized(true);
        } else {
          keycloak.login({
            action: 'login',
            scope: 'openid'
          });
        }
      });
    } else {
      setKeycloakInitialized(true);
    }
  }, []);

  if (!keycloakInitialized) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dashboard />
    </ThemeProvider>
  );
};

export default App;
