import React, { useEffect, useState } from 'react';
import { 
  Typography, 
  Button, 
  Box, 
  Paper, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getEstimates, deleteEstimate } from '../services/api';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

interface Estimate {
  id: string;
  requestNumber: string;
  name: string;
  contact: string;
  email: string;
  phone: string;
  campaignDesc: string;
  createdAt: string;
  updatedAt: string;
}


interface ScopeItem {
  id: string;
  title: string;
  products: any[];
  specifications: any;
}

const Estimates: React.FC = () => {
  const [estimates, setEstimates] = useState<Estimate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [estimateToDelete, setEstimateToDelete] = useState<string | null>(null);

  useEffect(() => {
    fetchEstimates();
  }, []);

  const fetchEstimates = async () => {
    try {
      const data = await getEstimates();
      setEstimates(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch estimates');
      console.error('Error fetching estimates:', err);
    } finally {
      setLoading(false);
    }
  };



  const handleEdit = (id: string) => {
    navigate(`/edit-estimate/${id}`);
  };

  const handleDeleteClick = (id: string) => {
    setEstimateToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!estimateToDelete) return;

    try {
      await deleteEstimate(estimateToDelete);
      setDeleteDialogOpen(false);
      setEstimateToDelete(null);
      fetchEstimates();
      setError(null);
    } catch (err) {
      setError('Failed to delete estimate');
      console.error('Error deleting estimate:', err);
    }
  };



  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box  sx={{ p: 3 }}>
      <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5" component="h2">Orçamentos</Typography>
        <Button 
          variant="contained" 
          color="primary"
          component={Link}
          to="/new-estimate"
        >
          Novo Orçamento
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper}>
        {(!estimates || estimates.length === 0) ? (
          <Box p={3} textAlign="center">
            <Typography variant="body1">Nenhum orçamento encontrado</Typography>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell>Contato</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Data de Criação</TableCell>
                <TableCell>Última Atualização</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estimates.map((estimate) => (
                <TableRow key={estimate.id} hover>
                  <TableCell>{estimate.requestNumber}</TableCell>
                  <TableCell>{estimate.contact}</TableCell>
                  <TableCell>{estimate.email}</TableCell>
                  <TableCell>{new Date(estimate.createdAt).toLocaleDateString('pt-BR')}</TableCell>
                  <TableCell>{new Date(estimate.updatedAt).toLocaleDateString('pt-BR')}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(estimate.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(estimate.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir este orçamento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Estimates;
