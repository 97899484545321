export interface Influencer {
  id: string;
  data: string;
  nome: string;
  segmento: string[];
  instagram: string;
  youtube: string;
  tiktok: string;
  base_insta: number;
  base_tiktok: number;
  base_youtube: number;
  value_combo_stories: number;
  value_reels: number;
  value_insert_youtube: number;
  value_tiktok: number;
  cidade: string;
  estado: string;
  aposta: boolean;
  contato: string;
  email: string;
  agencia: string;
}

export const influencerColumnNames: Record<string, string> = {
  id: "ID",
  data: "Data",
  nome: "Nome",
  segmento: "Segmento",
  instagram: "Instagram",
  youtube: "YouTube",
  tiktok: "TikTok",
  base_insta: "Instagram Base",
  base_tiktok: "TikTok Base",
  base_youtube: "YouTube Base",
  value_combo_stories: "Valor Combo Stories",
  value_reels: "Valor Reels",
  value_insert_youtube: "Valor Youtube",
  value_tiktok: "Valor TikTok",
  cidade: "Cidade",
  estado: "Estado",
  aposta: "Aposta",
  contato: "Contato",
  email: "Email",
  agencia: "Agência",
};

export const defaultVisibleColumns = {
  id: false,
  data: false,
  nome: true,
  segmento: true,
  instagram: true,
  youtube: true,
  tiktok: true,
  base_insta: false,
  base_tiktok: false,
  base_youtube: false,
  value_combo_stories: true,
  value_reels: true,
  value_insert_youtube: true,
  value_tiktok: true,
  cidade: true,
  estado: true,
  aposta: true,
  contato: false,
  email: true,
  agencia: false,
}; 