import axios from 'axios';
import { Influencer, Contact } from '../utils/constants/';
import axiosInstance from './axiosInstance';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all influencers data
export const fetchData = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/influencers`);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the data!', error);
    return [];
  }
};

// Fetch dropdown options for filters
export const fetchDropdownOptions = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/influencers/dropdown-options`);
    return response.data;
  } catch (error) {
    console.error('Error fetching dropdown options:', error);
    return null;
  }
};

// Fetch min/max values for filtering
export const fetchMinMaxOptions = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/influencers/minmax-values`);
    return response.data;
  } catch (error) {
    console.error('Error fetching min/max values:', error);
    return null;
  }
};

// Search influencers based on filters
export const searchInfluencers = async (filters: any) => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/influencers/search`, {
      params: filters, // Pass filters as query parameters
    });
    return response.data;
  } catch (error) {
    console.error('Error searching influencers:', error);
    return [];
  }
};

// Update influencer data by ID
export const updateInfluencer = async (id: string, influencerData: Influencer) => {
  try {
    const response = await axiosInstance.put(`${API_BASE_URL}/influencers/${id}`, influencerData);
    return response.data;
  } catch (error) {
    console.error(`Error updating influencer with ID: ${id}`, error);
    return null;
  }
};

export const deleteInfluencer = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`${API_BASE_URL}/influencers/${id}`);
    if (response.status === 204) {
      return true; // Success
    }
    return false; // Handle unexpected response statuses
  } catch (error) {
    console.error(`Error deleting influencer with ID: ${id}`, error);
    return false; // Failure
  }
};

// Create a new influencer
export const createInfluencer = async (influencerData: Influencer) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/influencers`, influencerData);
    return response.data;
  } catch (error) {
    console.error('Error creating influencer:', error);
    throw error;
  }
};

// Create a new estimate
export const createEstimate = async (estimateData: any) => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/estimates`, estimateData);
    return response.data;
  } catch (error) {
    console.error('Error creating estimate:', error);
    throw error;
  }
};

// Get all estimates
export const getEstimates = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/estimates`);
    return response.data;
  } catch (error) {
    console.error('Error fetching estimates:', error);
    throw error;
  }
};

export const getEstimateById = async (id: string) => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/estimates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching estimate:', error);
    throw error;
  }
};

export const updateEstimate = async (id: string, estimateData: any) => {
  try {
    const response = await axiosInstance.put(`${API_BASE_URL}/estimates/${id}`, estimateData);
    return response.data;
  } catch (error) {
    console.error('Error updating estimate:', error);
    throw error;
  }
};

export const getLatestRequestNumber = async (): Promise<string> => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/estimates/latest-number`);
    return response.data.requestNumber;
  } catch (error) {
    console.error('Error fetching latest request number:', error);
    return 'OR-001'; // Default value if no previous estimates exist
  }
};

export const deleteEstimate = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`${API_BASE_URL}/estimates/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting estimate:', error);
    throw error;
  }
};

export const getContacts = async (): Promise<Contact[]> => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/contacts`);
    return response.data;
  } catch (error) {
    console.error('Error fetching contacts:', error);
    throw error;
  }
};

export const createContact = async (contact: Omit<Contact, 'id'>): Promise<Contact> => {
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/contacts`, contact);
    return response.data;
  } catch (error) {
    console.error('Error creating contact:', error);
    throw error;
  }
};

export const updateContact = async (id: string, contact: Omit<Contact, 'id'>): Promise<Contact> => {
  try {
    const response = await axiosInstance.put(`${API_BASE_URL}/contacts/${id}`, contact);
    return response.data;
  } catch (error) {
    console.error('Error updating contact:', error);
    throw error;
  }
};

export const deleteContact = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`${API_BASE_URL}/contacts/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting contact:', error);
    throw error;
  }
};

export const updateEstimatesWithContact = async (contactId: string, contactData: Omit<Contact, 'id'>) => {
  try {
    const response = await axiosInstance.put(`${API_BASE_URL}/contacts/${contactId}/update-estimates`, contactData);
    return response.data;
  } catch (error) {
    console.error('Error updating estimates with contact:', error);
    throw error;
  }
};

// Get all proposals
export const getProposals = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/proposals`);
    return response.data;
  } catch (error) {
    console.error('Error fetching proposals:', error);
    throw error;
  }
};

// Delete a proposal
export const deleteProposal = async (id: string) => {
  try {
    await axiosInstance.delete(`${API_BASE_URL}/proposals/${id}`);
  } catch (error) {
    console.error('Error deleting proposal:', error);
    throw error;
  }
};

