import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Snackbar,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { getContacts, createContact, updateContact, deleteContact, updateEstimatesWithContact } from '../services/api';
import { Contact } from '../utils/constants';
import { formatPhone } from '../utils/formatters';

const Contacts: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingContact, setEditingContact] = useState<Contact | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    brand: '',
    email: '',
    phone: '',
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState<Contact | null>(null);
  const [updateEstimatesDialogOpen, setUpdateEstimatesDialogOpen] = useState(false);
  const [pendingContactUpdate, setPendingContactUpdate] = useState<{
    contact: Contact;
    formData: typeof formData;
  } | null>(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        setLoading(true);
        const data = await getContacts();
        setContacts(data || []);
        setError(null);
      } catch (error) {
        console.error('Error fetching contacts:', error);
        setError('Falha ao carregar contatos');
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const handleDeleteConfirm = async () => {
    if (!contactToDelete) return;
    
    try {
      await deleteContact(contactToDelete.id);
      setContacts(contacts.filter(c => c.id !== contactToDelete.id));
      setDeleteDialogOpen(false);
      setContactToDelete(null);
      setAlertMessage('Contato deletado com sucesso');
      setAlertSeverity('success');
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage('Erro ao deletar contato');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingContact(null);
    setFormData({
      name: '',
      brand: '',
      email: '',
      phone: ''
    });
  };

  const handleSubmit = async () => {
    try {
      if (editingContact) {
        // Store the pending update and show confirmation dialog
        setPendingContactUpdate({
          contact: editingContact,
          formData: formData
        });
        setUpdateEstimatesDialogOpen(true);
      } else {
        // For new contacts, proceed as normal
        const newContact = await createContact(formData);
        setContacts([...contacts, newContact]);
        setAlertMessage('Contato criado com sucesso');
        setAlertSeverity('success');
        setAlertOpen(true);
        handleCloseDialog();
      }
    } catch (error) {
      setAlertMessage('Erro ao salvar contato');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleDeleteClick = (contact: Contact) => {
    setContactToDelete(contact);
    setDeleteDialogOpen(true);
  };

  const handleOpenDialog = (contact?: Contact) => {
    if (contact) {
      setEditingContact(contact);
      setFormData({
        name: contact.name,
        brand: contact.brand,
        email: contact.email,
        phone: contact.phone
      });
    }
    setOpenDialog(true);
  };

  const handleUpdateConfirmation = async (updateEstimates: boolean) => {
    if (!pendingContactUpdate) return;

    try {
      const { contact, formData } = pendingContactUpdate;
      
      // Update the contact
      await updateContact(contact.id, formData);
      
      // If confirmed, update estimates with the new contact info
      if (updateEstimates) {
        await updateEstimatesWithContact(contact.id, formData);
      }
      
      // Update local state
      setContacts(contacts.map(c => c.id === contact.id ? { ...c, ...formData } : c));
      setAlertMessage('Contato atualizado com sucesso');
      setAlertSeverity('success');
      setAlertOpen(true);
      
      // Clean up
      setUpdateEstimatesDialogOpen(false);
      setPendingContactUpdate(null);
      handleCloseDialog();
    } catch (error) {
      setAlertMessage('Erro ao atualizar contato');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">Contatos</Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
          Adicionar Contato
        </Button>
      </Box>

      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="body1" sx={{ py: 2 }}>
                      Nenhum contato cadastrado
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                contacts.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>{contact.brand}</TableCell>
                    <TableCell>{contact.email}</TableCell>
                    <TableCell>{contact.phone}</TableCell>
                    <TableCell align="right">
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => handleOpenDialog(contact)}
                        sx={{ mr: 1 }}
                      >
                        Editar
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        color="error"
                        onClick={() => handleDeleteClick(contact)}
                      >
                        Deletar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Create/Edit Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{editingContact ? 'Editar Contato' : 'Criar Contato'}</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              label="Nome"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <TextField
              fullWidth
              label="Marca"
              value={formData.brand}
              onChange={(e) => setFormData({ ...formData, brand: e.target.value })}
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <TextField
              fullWidth
              label="Telefone"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: formatPhone(e.target.value) })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingContact ? 'Atualizar' : 'Adicionar'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Deletar Contato</DialogTitle>
        <DialogContent>
          Tem certeza que deseja deletar o contato {contactToDelete?.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Estimates Confirmation Dialog */}
      <Dialog 
        open={updateEstimatesDialogOpen} 
        onClose={() => setUpdateEstimatesDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Atualizar Orçamentos</DialogTitle>
        <DialogContent>
          <Typography>
            Deseja atualizar as informações deste contato em todos os orçamentos existentes?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUpdateConfirmation(false)}>
            Atualizar Apenas Contato
          </Button>
          <Button onClick={() => handleUpdateConfirmation(true)} variant="contained" color="primary">
            Atualizar Tudo
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity={alertSeverity} onClose={() => setAlertOpen(false)}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contacts; 