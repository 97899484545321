import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getEstimates, getEstimateById } from '../services/api';

interface Estimate {
  id: string;
  requestNumber: string;
  name: string;
  contact: string;
  email: string;
  phone: string;
  campaignDesc: string;
  createdAt: string;
  updatedAt: string;
  scopes: ScopeItem[];
  influencers: EstimateInfluencer[];
}

interface ScopeItem {
  id: string;
  title: string;
  influencer: {
    id: string;
    name: string;
  };
  products: Product[];
  specifications: {
    // ... existing specifications properties ...
  };
}

interface Product {
  id: string;
  type: string;
  quantity: number;
  observation: string;
}

interface EstimateInfluencer {
  id: string;
  name: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
  scopeValues: {
    scopeId: string;
    value: number;
  }[];
  proposalValues?: {
    scopeId: string;
    value: number;
  }[];
}

const NewProposal: React.FC = () => {
  const [estimates, setEstimates] = useState<Estimate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedEstimate, setSelectedEstimate] = useState<Estimate | null>(null);
  const [activeScope, setActiveScope] = useState<string | null>(null);
  const [proposalData, setProposalData] = useState<{
    scopeTotals: { [scopeId: string]: { estimate: number; proposal: number } }
  }>({
    scopeTotals: {}
  });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Nova Proposta - Lub';
    fetchEstimates();
  }, []);

  const fetchEstimates = async () => {
    try {
      const data = await getEstimates();
      setEstimates(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch estimates');
      console.error('Error fetching estimates:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEstimateSelect = async (id: string) => {
    try {
      setLoading(true);
      const estimateData = await getEstimateById(id);
      setSelectedEstimate(estimateData);
      
      const initialScopeTotals: { [scopeId: string]: { estimate: number; proposal: number } } = {};
      estimateData.scopes.forEach((scope: ScopeItem) => {
        initialScopeTotals[scope.id] = { 
          estimate: calculateEstimateTotal(scope.id, estimateData.influencers),
          proposal: calculateEstimateTotal(scope.id, estimateData.influencers)
        };
      });
      
      setProposalData({ scopeTotals: initialScopeTotals });
      
      if (estimateData.scopes.length > 0) {
        setActiveScope(estimateData.scopes[0].id);
      }
      
      setError(null);
    } catch (err) {
      setError('Failed to fetch estimate details');
      console.error('Error fetching estimate details:', err);
    } finally {
      setLoading(false);
    }
  };

  const calculateEstimateTotal = (scopeId: string, influencers: EstimateInfluencer[]) => {
    return influencers.reduce((total, influencer) => {
      const scopeValue = influencer.scopeValues?.find(sv => sv.scopeId === scopeId)?.value || 0;
      return total + scopeValue;
    }, 0);
  };

  const handleInfluencerProposalChange = (influencerId: string, scopeId: string, newValue: number) => {
    setSelectedEstimate(prev => {
      if (!prev) return null;
      
      const updatedInfluencers = prev.influencers.map(inf => {
        if (inf.id === influencerId) {
          const proposalValues = inf.proposalValues || inf.scopeValues.map(sv => ({ 
            scopeId: sv.scopeId, 
            value: sv.value 
          }));
          
          const updatedProposalValues = proposalValues.map(pv => 
            pv.scopeId === scopeId ? { ...pv, value: newValue } : pv
          );
          
          return { ...inf, proposalValues: updatedProposalValues };
        }
        return inf;
      });
      
      return { ...prev, influencers: updatedInfluencers };
    });
    
    updateScopeTotal(scopeId);
  };

  const updateScopeTotal = (scopeId: string) => {
    if (!selectedEstimate) return;
    
    const proposalTotal = selectedEstimate.influencers.reduce((total, influencer) => {
      const proposalValue = influencer.proposalValues?.find(pv => pv.scopeId === scopeId)?.value || 
                           influencer.scopeValues?.find(sv => sv.scopeId === scopeId)?.value || 0;
      return total + proposalValue;
    }, 0);
    
    const estimateTotal = calculateEstimateTotal(scopeId, selectedEstimate.influencers);
    
    setProposalData(prev => ({
      ...prev,
      scopeTotals: {
        ...prev.scopeTotals,
        [scopeId]: { estimate: estimateTotal, proposal: proposalTotal }
      }
    }));
  };

  const handleScopeTotalChange = (scopeId: string, newTotal: number) => {
    if (!selectedEstimate) return;
    
    setProposalData(prev => ({
      ...prev,
      scopeTotals: {
        ...prev.scopeTotals,
        [scopeId]: { 
          ...prev.scopeTotals[scopeId],
          proposal: newTotal 
        }
      }
    }));
    
    const currentTotal = selectedEstimate.influencers.reduce((total, influencer) => {
      const value = influencer.proposalValues?.find(pv => pv.scopeId === scopeId)?.value || 
                   influencer.scopeValues?.find(sv => sv.scopeId === scopeId)?.value || 0;
      return total + value;
    }, 0);
    
    if (currentTotal === 0) return;
    
    const ratio = newTotal / currentTotal;
    
    setSelectedEstimate(prev => {
      if (!prev) return null;
      
      const updatedInfluencers = prev.influencers.map(inf => {
        const currentValue = inf.proposalValues?.find(pv => pv.scopeId === scopeId)?.value || 
                            inf.scopeValues?.find(sv => sv.scopeId === scopeId)?.value || 0;
        
        const proposalValues = inf.proposalValues || inf.scopeValues.map(sv => ({ 
          scopeId: sv.scopeId, 
          value: sv.value 
        }));
        
        const updatedProposalValues = proposalValues.map(pv => 
          pv.scopeId === scopeId ? { ...pv, value: currentValue * ratio } : pv
        );
        
        return { ...inf, proposalValues: updatedProposalValues };
      });
      
      return { ...prev, influencers: updatedInfluencers };
    });
  };

  const formatToBRL = (value: number) => {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (!selectedEstimate) {
    return (
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Nova Proposta
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Typography variant="h6" sx={{ mb: 2 }}>
            Selecione um Orçamento
          </Typography>

          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Número</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {estimates.map((estimate) => (
                    <TableRow
                      key={estimate.id}
                      hover
                      onClick={() => handleEstimateSelect(estimate.id)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>{estimate.requestNumber}</TableCell>
                      <TableCell>{estimate.contact}</TableCell>
                      <TableCell>{estimate.name}</TableCell>
                      <TableCell>{estimate.email}</TableCell>
                      <TableCell>{estimate.phone}</TableCell>
                      <TableCell>
                        {new Date(estimate.createdAt).toLocaleDateString('pt-BR')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Nova Proposta - {selectedEstimate.requestNumber}
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ width: '100%', bgcolor: 'background.paper', mb: 3 }}>
          <Tabs 
            value={activeScope} 
            onChange={(_, newValue) => setActiveScope(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {selectedEstimate.scopes.map(scope => (
              <Tab key={scope.id} label={scope.title} value={scope.id} />
            ))}
          </Tabs>
        </Box>

        {activeScope && (
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography variant="h6">
                {selectedEstimate.scopes.find(s => s.id === activeScope)?.title}
              </Typography>
              
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label="Valor Total Orçamento"
                  value={formatToBRL(proposalData.scopeTotals[activeScope]?.estimate || 0)}
                  InputProps={{
                    readOnly: true,
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  variant="outlined"
                  size="small"
                />
                
                <TextField
                  label="Valor Total Proposta"
                  value={formatToBRL(proposalData.scopeTotals[activeScope]?.proposal || 0)}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/\D/g, '');
                    const numberValue = Number(rawValue) / 100;
                    handleScopeTotalChange(activeScope, numberValue);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  variant="outlined"
                  size="small"
                />
              </Box>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Influenciador</TableCell>
                    <TableCell>Produtos</TableCell>
                    <TableCell>Valor Orçamento</TableCell>
                    <TableCell>Valor Proposta</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedEstimate.influencers.map(influencer => {
                    const scopeValue = influencer.scopeValues?.find(sv => sv.scopeId === activeScope)?.value || 0;
                    const proposalValue = influencer.proposalValues?.find(pv => pv.scopeId === activeScope)?.value || scopeValue;
                    
                    const currentScope = selectedEstimate.scopes.find(s => s.id === activeScope);
                    const productNames = currentScope ? currentScope.products.map((p: Product) => 
                      `${p.quantity}x ${p.type}`
                    ).join(", ") : "";
                    
                    return (
                      <TableRow key={influencer.id}>
                        <TableCell>{influencer.name}</TableCell>
                        <TableCell>{productNames}</TableCell>
                        <TableCell>
                          <TextField
                            value={formatToBRL(scopeValue)}
                            InputProps={{
                              readOnly: true,
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={formatToBRL(proposalValue)}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace(/\D/g, '');
                              const numberValue = Number(rawValue) / 100;
                              handleInfluencerProposalChange(influencer.id, activeScope || '', numberValue);
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            fullWidth
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => {/* Save proposal logic */}}
          >
            Salvar Proposta
          </Button>
          <Button 
            variant="outlined"
            onClick={() => setSelectedEstimate(null)}
          >
            Voltar
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NewProposal;