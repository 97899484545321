import axios from 'axios';
import keycloak from './keycloak';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (process.env.REACT_APP_KEYCLOAK_ENABLED === 'true') {
      try {
        // Check if token needs to be refreshed
        if (keycloak.isTokenExpired()) {
          await keycloak.updateToken(70); // Refresh if less than 70 seconds remaining
        }
        // Add authorization header
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      } catch (error) {
        console.error('Failed to refresh token:', error);
        keycloak.login();
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      // Unauthorized - redirect to login
      keycloak.login();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance; 