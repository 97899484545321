import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, Grid, TextField, Snackbar, Alert, Checkbox, FormControl, RadioGroup, FormControlLabel, Radio, Select, MenuItem, ListItemText } from '@mui/material';
import { Influencer } from '../utils/constants/';
import { deleteInfluencer, updateInfluencer } from '../services/api';

interface ModalDetailsProps {
  open: boolean;
  onClose: () => void;
  row: Influencer;
  reload: () => void;
  dropdownOptions?: {
    segmento: string[];
    estado: string[];
    cidade: string[];
    agencia: string[];
  };
}

const ModalDetails: React.FC<ModalDetailsProps> = ({ open, onClose, row, reload, dropdownOptions }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedRow, setEditedRow] = useState<Influencer>(row);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    setEditedRow(row);
  }, [row]);

  const onCloseLocal = () => {
    setEditMode(false);
    onClose();
  };

  const handleDelete = () => {
    if(editMode && editedRow.id){
      deleteInfluencer(editedRow.id).then((updatedData: any) => {
        if (updatedData) {
          setSnackbarMessage('Influencer deleted successfully');
          setSnackbarSeverity('success');
          setEditMode(!editMode);
          onCloseLocal()
          reload()
        } else {
          setSnackbarMessage('Failed to delete influencer');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      });
    }
    else{
      setEditMode(!editMode);
    }
  }
  const handleEditToggle = () => {
    if (editMode && editedRow.id) {
      updateInfluencer(editedRow.id, editedRow).then((updatedData: any) => {
        if (updatedData) {
          setSnackbarMessage('Influencer updated successfully');
          setSnackbarSeverity('success');
          setEditMode(!editMode);
        } else {
          setSnackbarMessage('Failed to update influencer');
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      });
    }
    else{
      setEditMode(!editMode);
    }
  };

  const handleFieldChange = (key: keyof Influencer, value: string) => {
    const numericFields: (keyof Influencer)[] = [
      'base_insta',
      'base_tiktok',
      'base_youtube',
      'value_combo_stories',
      'value_reels',
      'value_insert_youtube',
      'value_tiktok',
    ];

    let updatedValue: string | number | boolean = value;

    if (numericFields.includes(key)) {
      updatedValue = Number(value);
    } else if (typeof editedRow[key] === 'boolean') {
      updatedValue = value === 'true';
    }

    setEditedRow({ ...editedRow, [key]: updatedValue });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 'auto',
            maxWidth: '90%',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Grid container spacing={2}>
            {Object.keys(editedRow).map((key, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  minWidth: 0,
                  flexBasis: 'auto',
                  wordBreak: 'break-word',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {key}
                </Typography>
                {editMode ? (
                  (() => {
                    // Handle boolean fields
                    if (typeof editedRow[key as keyof Influencer] === 'boolean') {
                      return (
                        <FormControl>
                          <RadioGroup
                            row
                            value={editedRow[key as keyof Influencer] ? 'true' : 'false'}
                            onChange={(e) => handleFieldChange(key as keyof Influencer, e.target.value)}
                          >
                            <FormControlLabel value="true" control={<Radio size="small" />} label="Sim" />
                            <FormControlLabel value="false" control={<Radio size="small" />} label="Não" />
                          </RadioGroup>
                        </FormControl>
                      );
                    }
                    
                    // Handle segmento array field
                    if (key === 'segmento') {
                      return (
                        <FormControl fullWidth size="small">
                          <Select
                            multiple
                            value={editedRow.segmento}
                            onChange={(e) => {
                              const value = e.target.value as string[];
                              setEditedRow({ ...editedRow, segmento: value });
                            }}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                          >
                            {dropdownOptions?.segmento?.map((segment) => (
                              <MenuItem key={segment} value={segment}>
                                <Checkbox checked={editedRow.segmento.indexOf(segment) > -1} />
                                <ListItemText primary={segment} />
                              </MenuItem>
                            ))}
                            {/* Option to add new segment */}
                            <MenuItem>
                              <TextField
                                placeholder="Adicionar novo segmento"
                                size="small"
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    const input = e.target as HTMLInputElement;
                                    const newSegment = input.value.trim();
                                    if (newSegment && !editedRow.segmento.includes(newSegment)) {
                                      setEditedRow({
                                        ...editedRow,
                                        segmento: [...editedRow.segmento, newSegment]
                                      });
                                      input.value = '';
                                    }
                                  }
                                }}
                              />
                            </MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }

                    // Default TextField for other types
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={editedRow[key as keyof Influencer]}
                        onChange={(e) => handleFieldChange(key as keyof Influencer, e.target.value)}
                        type={typeof editedRow[key as keyof Influencer] === 'number' ? 'number' : 'text'}
                      />
                    );
                  })()
                ) : (
                  <Typography>
                    {(() => {
                      if (typeof editedRow[key as keyof Influencer] === 'boolean') {
                        return editedRow[key as keyof Influencer] ? 'Sim' : 'Não';
                      }
                      if (key === 'segmento') {
                        return (editedRow[key as keyof Influencer] as string[]).join(', ');
                      }
                      return editedRow[key as keyof Influencer] || 'N/A';
                    })()}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Box>  <Button variant="contained" color="primary" onClick={handleEditToggle}>
              {editMode ? 'Salvar' : 'Editar'}
            </Button> {editMode &&  <Button variant="contained" color="error" onClick={handleDelete}>
             Deletar
            </Button>}</Box>
           
           
            <Button onClick={onCloseLocal} variant="contained">
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar for success/error message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModalDetails;
