import React, { useState, useEffect } from 'react';
import InfluencerTable from '../components/InfluencerTable';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Box, Grid, TextField, Checkbox, FormControlLabel, MenuItem, Autocomplete } from '@mui/material';
import { createInfluencer, fetchData } from '../services/api';
import { Influencer } from '../utils/constants';

const ESTADOS_BRASIL = [
  'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA',
  'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN',
  'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
];

const SEGMENTOS = [
  'Agro', 'Arte', 'Automóveis', 'Beleza', 'Cinema & Séries', 'Ciência', 
  'Culinária', 'Cultura Pop', 'DIY (Faça Você Mesmo)', 'Educação', 
  'Empreendedorismo', 'Engenharia/Arquitetura', 'Entretenimento', 'Esportes',
  'Família', 'Finanças', 'Fitness', 'Games', 'Geek', 'Humor', 'LGBTQIAP+',
  'Lifestyle', 'Maquiagem', 'Maternidade', 'Moda', 'Música', 'Pet', 'Saúde',
  'Tecnologia', 'Viagem'
];

const Home: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedInfluencers, setSelectedInfluencers] = useState<Array<any>>([]);
  const [customInfluencerForm, setCustomInfluencerForm] = useState({
    nome: '',
    email: '',
    instagram: '',
    youtube: '',
    tiktok: '',
    contato: '',
    aposta: false,
    base_insta: 0,
    base_tiktok: 0,
    base_youtube: 0,
    value_combo_stories: 0,
    value_reels: 0,
    value_insert_youtube: 0,
    value_tiktok: 0,
    cidade: '',
    estado: '',
    agencia: '',
    mini_bio: '',
    status: '',
    segmento: [] as string[]
  });
  const [agencies, setAgencies] = useState<string[]>([]);
  const [customAgency, setCustomAgency] = useState('');

  const handleClose = () => {
    setOpen(false);
    setSelectedInfluencers([]);
  };

  const handleSelectInfluencer = async (selected: { 
    id: string;
    name: string;
    instagram?: string;
    youtube?: string;
    tiktok?: string;
  }) => {
    try {
      const influencer = {
        id: "",
        data: new Date().toISOString(),
        nome: selected.name,
        email: customInfluencerForm.email,
        instagram: selected.instagram || '',
        youtube: selected.youtube || '',
        tiktok: selected.tiktok || '',
        contato: customInfluencerForm.contato,
        aposta: customInfluencerForm.aposta,
        base_insta: customInfluencerForm.base_insta,
        base_tiktok: customInfluencerForm.base_tiktok,
        base_youtube: customInfluencerForm.base_youtube,
        value_combo_stories: customInfluencerForm.value_combo_stories,
        value_reels: customInfluencerForm.value_reels,
        value_insert_youtube: customInfluencerForm.value_insert_youtube,
        value_tiktok: customInfluencerForm.value_tiktok,
        cidade: customInfluencerForm.cidade,
        estado: customInfluencerForm.estado,
        agencia: customInfluencerForm.agencia,
        mini_bio: customInfluencerForm.mini_bio || '',
        status: customInfluencerForm.status || '',
        segmento: customInfluencerForm.segmento || []
      };
      await createInfluencer(influencer);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error('Error creating influencer:', error);
    }
  };

  const handleAgencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomAgency(value);
    setCustomInfluencerForm(prev => ({
      ...prev,
      agencia: value
    }));
  };

  useEffect(() => {
    const fetchAgencies = async () => {
      try {
        const influencers = await fetchData();
        const uniqueAgencies = Array.from(new Set(influencers
          .map((inf: Influencer) => inf.agencia)
          .filter((agency: string) => agency && agency.trim() !== '')
        )) as string[];
        setAgencies(uniqueAgencies);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };
    fetchAgencies();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <h2>Influencers</h2>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpen(true)}
        >
          Adicionar Influenciador
        </Button>
      </Box>
      <InfluencerTable/>

      {/* Influencer Selection Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Adicionar Novo Influenciador</DialogTitle>
        <Divider sx={{margin:0}}/>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* Basic Information */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome"
                  value={customInfluencerForm.nome}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    nome: e.target.value
                  }))}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  value={customInfluencerForm.email}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    email: e.target.value
                  }))}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Contato"
                  value={customInfluencerForm.contato}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    contato: e.target.value
                  }))}
                />
              </Grid>

              {/* Social Media Handles */}
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Instagram"
                  value={customInfluencerForm.instagram}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    instagram: e.target.value
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Youtube"
                  value={customInfluencerForm.youtube}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    youtube: e.target.value
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="TikTok"
                  value={customInfluencerForm.tiktok}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    tiktok: e.target.value
                  }))}
                />
              </Grid>

              {/* Follower Base Numbers */}
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Base Instagram"
                  value={customInfluencerForm.base_insta}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    base_insta: Number(e.target.value)
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Base YouTube"
                  value={customInfluencerForm.base_youtube}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    base_youtube: Number(e.target.value)
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Base TikTok"
                  value={customInfluencerForm.base_tiktok}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    base_tiktok: Number(e.target.value)
                  }))}
                />
              </Grid>

              {/* Values */}
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Valor Combo Stories"
                  value={customInfluencerForm.value_combo_stories}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    value_combo_stories: Number(e.target.value)
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Valor Reels"
                  value={customInfluencerForm.value_reels}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    value_reels: Number(e.target.value)
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Valor Insert YouTube"
                  value={customInfluencerForm.value_insert_youtube}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    value_insert_youtube: Number(e.target.value)
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Valor TikTok"
                  value={customInfluencerForm.value_tiktok}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    value_tiktok: Number(e.target.value)
                  }))}
                />
              </Grid>

              {/* Location */}
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Estado"
                  value={customInfluencerForm.estado}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    estado: e.target.value
                  }))}
                >
                  {ESTADOS_BRASIL.map((estado) => (
                    <MenuItem key={estado} value={estado}>
                      {estado}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Cidade"
                  value={customInfluencerForm.cidade}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    cidade: e.target.value
                  }))}
                />
              </Grid>

              {/* Additional Info */}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  freeSolo
                  value={customInfluencerForm.agencia}
                  onChange={(e, newValue) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    agencia: newValue || ''
                  }))}
                  options={agencies}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Agência"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Status"
                  value={customInfluencerForm.status || ''}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    status: e.target.value
                  }))}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Mini Bio"
                  value={customInfluencerForm.mini_bio || ''}
                  onChange={(e) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    mini_bio: e.target.value
                  }))}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customInfluencerForm.aposta}
                      onChange={(e) => setCustomInfluencerForm(prev => ({
                        ...prev,
                        aposta: e.target.checked
                      }))}
                    />
                  }
                  label="Aposta"
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  value={customInfluencerForm.segmento}
                  onChange={(e, newValue) => setCustomInfluencerForm(prev => ({
                    ...prev,
                    segmento: newValue
                  }))}
                  options={SEGMENTOS}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Segmentos"
                      placeholder="Selecione os segmentos"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button 
            variant="contained" 
            onClick={() => handleSelectInfluencer({
              id: crypto.randomUUID(),
              name: customInfluencerForm.nome,
              instagram: customInfluencerForm.instagram,
              youtube: customInfluencerForm.youtube,
              tiktok: customInfluencerForm.tiktok
            })}
            disabled={!customInfluencerForm.nome || !customInfluencerForm.email}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;
