import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getProposals, deleteProposal } from '../services/api';

interface Proposal {
  id: string;
  requestNumber: string;
  name: string;
  contact: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

const Proposals: React.FC = () => {
  const navigate = useNavigate();
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        setLoading(true);
        const data = await getProposals();
        setProposals(data || []);
        setError(null);
      } catch (error) {
        console.error('Error fetching proposals:', error);
        setError('Falha ao carregar propostas');
      } finally {
        setLoading(false);
      }
    };

    fetchProposals();
  }, []);

  const handleEdit = (id: string) => {
    navigate(`/edit-proposal/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Tem certeza que deseja deletar esta proposta?')) {
      try {
        await deleteProposal(id);
        setProposals(proposals.filter(proposal => proposal.id !== id));
      } catch (error) {
        console.error('Error deleting proposal:', error);
        setError('Falha ao deletar proposta');
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h5">Propostas</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/new-proposal')}
        >
          Nova Proposta
        </Button>
      </Box>

      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Contato</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Data de Criação</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proposals.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="body1" sx={{ py: 2 }}>
                      Nenhuma proposta cadastrada
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                proposals.map((proposal) => (
                  <TableRow key={proposal.id}>
                    <TableCell>{proposal.requestNumber}</TableCell>
                    <TableCell>{proposal.name}</TableCell>
                    <TableCell>{proposal.contact}</TableCell>
                    <TableCell>{proposal.email}</TableCell>
                    <TableCell>
                      {new Date(proposal.createdAt).toLocaleDateString('pt-BR')}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(proposal.id)}
                        sx={{ mr: 1 }}
                      >
                        Editar
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        color="error"
                        onClick={() => handleDelete(proposal.id)}
                      >
                        Deletar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Proposals; 