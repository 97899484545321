import React, { useState, useEffect } from 'react';
import {
  TextField, Table, TableBody, TableCell, TableHead, TableRow,
  Paper, Checkbox, Box, Button,
  CircularProgress,
  Divider
} from '@mui/material';
import { Influencer } from '../utils/constants/';
import ModalDetails from './ModalDetails';
import ColumnToggles from './ColumnToggles';
import SearchForm from './SearchForm';
import { formatDate, formatCurrency } from '../utils/format';
import { influencerColumnNames, defaultVisibleColumns } from '../utils/constants/';
import { searchInfluencers, fetchDropdownOptions, fetchData, fetchMinMaxOptions } from '../services/api';
import LinkIcon from '@mui/icons-material/Link';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TikTokIcon from './icons/TikTokIcon';

interface InfluencerTableProps {
  onSelectInfluencer?: (influencer: { id: string; name: string; instagram?: string; youtube?: string; tiktok?: string }) => void;
  onSelectInfluencers?: (influencers: Array<{ id: string; name: string; instagram?: string; youtube?: string; tiktok?: string }>) => void;
  selectedInfluencers?: Array<any>;
  isInfluencerAdded?: (influencerId: string) => boolean;
  selectionMode?: boolean;
}

const InfluencerTable: React.FC<InfluencerTableProps> = ({ onSelectInfluencers, selectionMode = false }) => {
  const [data, setData] = useState<Influencer[]>([]);
  const [dropdownOptions, setDropdownOptions] = useState<any>(null);
  const [minMaxValues, setMinMaxValues] = useState<any>(null);
  const [selectedColumns, setSelectedColumns] = useState<Record<string, boolean>>(defaultVisibleColumns);
  const [search, setSearch] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [openRowModal, setOpenRowModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Influencer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showColumnToggles, setShowColumnToggles] = useState(false); // State to toggle ColumnToggles visibility
  const [sortColumn, setSortColumn] = useState<string>('agencia');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  

  useEffect(() => {
    loadData();
    loadDropdownOptions();
    loadMinMaxValues();
  }, []);

  const loadData = async (filters: any = {}) => {
    try {
      setLoading(true);
      setError(null);
      const fetchedData = await searchInfluencers(filters); // Call searchInfluencers for initial load as well
      setData(fetchedData);
    } catch (error) {
      setError('Failed to load data.');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadDropdownOptions = async () => {
    try {
      const options = await fetchDropdownOptions(); // Use fetchDropdownOptions from api.ts
      setDropdownOptions(options);
    } catch (error) {
      setError('Failed to load dropdown options.');
      console.error('Error fetching dropdown options:', error);
    }
  };

  const loadMinMaxValues = async () => {
    try {
      const options = await fetchMinMaxOptions();
      setMinMaxValues(options);
    } catch (error) {
      setError('Failed to load min max values.');
      console.error('Error fetching load min max values:', error);
    }
  }

  const handleSearch = async (filters: any) => {
    loadData(filters);
  };

  const handleReset = () => {
    setSearch('');
    loadData(); // Reload data without filters
  };

  const handleRowSelect = (id: string) => {
    const newSelectedRows = new Set(selectedRows);
    newSelectedRows.has(id) ? newSelectedRows.delete(id) : newSelectedRows.add(id);
    setSelectedRows(newSelectedRows);
    if (onSelectInfluencers) {
      const selectedInfluencers = data
        .filter(row => newSelectedRows.has(row.id))
        .map(row => ({
          id: row.id,
          name: row.nome,
          instagram: row.instagram,
          youtube: row.youtube,
          tiktok: row.tiktok
        }));
      onSelectInfluencers(selectedInfluencers);
    }
  };

  const handleOpenRowModal = (row: Influencer) => {
    console.log(row)
    setSelectedRow(null)
    setSelectedRow(row);
    setOpenRowModal(true);
  };

  const handleToggleFilters = () => {
    setShowColumnToggles((prevState) => !prevState); // Toggle the visibility of ColumnToggles
  };

  const handleAddSelected = () => {
    if (onSelectInfluencers && selectedRows.size > 0) {
      const selectedInfluencers = data
        .filter(row => selectedRows.has(row.id))
        .map(row => ({
          id: row.id,
          name: row.nome,
          instagram: row.instagram,
          youtube: row.youtube,
          tiktok: row.tiktok
        }));
      onSelectInfluencers(selectedInfluencers);
    }
  };

  const handleAddSingle = (row: Influencer) => {
    if (onSelectInfluencers) {
      onSelectInfluencers([{
        id: row.id,
        name: row.nome,
        instagram: row.instagram,
        youtube: row.youtube,
        tiktok: row.tiktok
      }]);
    }
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortData = (data: Influencer[]) => {
    if (!sortColumn) return data;

    return [...data].sort((a, b) => {
      // Special handling for agencies
      if (sortColumn === 'agencia') {
        // Order: 1. Marina Baldin from LUB, 2. Other LUB, 3. Others
        const isAMarinaLUB = a.nome === 'Marina Baldin' && a.agencia === 'LUB';
        const isBMarinaLUB = b.nome === 'Marina Baldin' && b.agencia === 'LUB';
        
        if (isAMarinaLUB && !isBMarinaLUB) return -1;
        if (!isAMarinaLUB && isBMarinaLUB) return 1;
        if (a.agencia === 'LUB' && b.agencia !== 'LUB') return -1;
        if (a.agencia !== 'LUB' && b.agencia === 'LUB') return 1;
      }

      let aValue = a[sortColumn as keyof Influencer];
      let bValue = b[sortColumn as keyof Influencer];

      // Handle special cases
      if (sortColumn === 'data') {
        aValue = new Date(aValue as string).getTime();
        bValue = new Date(bValue as string).getTime();
      } else if (typeof aValue === 'string') {
        aValue = (aValue as string).toLowerCase();
        bValue = (bValue as string).toLowerCase();
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const filteredData = sortData((data && data.length > 0) ? data
    .filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          (typeof value === 'string' || Array.isArray(value)) &&
          value.toString().toLowerCase().includes(search.toLowerCase())
      )
    ) : []);

  return (
    <Box>
      {dropdownOptions && (
        <SearchForm 
          onSearch={handleSearch} 
          onReset={handleReset} 
          dropdownOptions={dropdownOptions} 
          minMaxValues={minMaxValues}
          handleToggleFilters={handleToggleFilters}
        />
      )}


      {showColumnToggles && (
        <Box sx={{ mt: 2 }}>
          <ColumnToggles
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Box>
      )}

      {loading ? (
        <Box justifyContent={'center'}><CircularProgress /></Box>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Box>

          
          <Paper sx={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {selectionMode && (
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.size > 0 && selectedRows.size === filteredData.length}
                        indeterminate={selectedRows.size > 0 && selectedRows.size < filteredData.length}
                        onChange={() => {
                          const newSelectedRows = selectedRows.size === filteredData.length
                            ? new Set<string>()
                            : new Set<string>(filteredData.map(row => row.id));
                          setSelectedRows(newSelectedRows);

                          handleAddSelected();
                        }}
                      />
                    </TableCell>
                  )}
                  {Object.keys(selectedColumns).map(
                    (column) =>
                      selectedColumns[column] && (
                        <TableCell 
                          key={column}
                          onClick={() => handleSort(column)}
                          style={{ cursor: 'pointer' }}
                        >
                          {influencerColumnNames[column]}
                          {sortColumn === column && (
                            <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                          )}
                        </TableCell>
                      )
                  )}
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row) => (
                  <TableRow key={row.id}>
                    {selectionMode && (
                      <TableCell>
                        <Checkbox
                          checked={selectedRows.has(row.id)}
                          onChange={() => handleRowSelect(row.id)}
                        />
                      </TableCell>
                    )}
                    {Object.keys(selectedColumns).map(
                      (column) =>
                        selectedColumns[column] && (
                          <TableCell key={column}>
                             {column === 'data' ? (
                              formatDate(row[column as keyof Influencer] as string)
                            ) : column.startsWith('value_') ? (
                              formatCurrency(row[column as keyof Influencer] as number)
                            ) : column === 'instagram' && row[column as keyof Influencer] ? (
                              <a href={row[column as keyof Influencer] as string} target="_blank" rel="noopener noreferrer">
                                <InstagramIcon />
                              </a>
                            ) : column === 'youtube' && row[column as keyof Influencer] ? (
                              <a href={row[column as keyof Influencer] as string} target="_blank" rel="noopener noreferrer">
                                <YouTubeIcon />
                              </a>
                            ) : column === 'tiktok' && row[column as keyof Influencer] ? (
                              <a href={row[column as keyof Influencer] as string} target="_blank" rel="noopener noreferrer">
                                <TikTokIcon />
                              </a>
                            ) : column === 'aposta' ? (
                              row[column as keyof Influencer] ? 'Sim' : 'Não'
                            ) : Array.isArray(row[column as keyof Influencer]) ? (
                              (row[column as keyof Influencer] as string[]).join(', ')
                            ) : (
                              row[column as keyof Influencer] || '-'
                            )}
                          </TableCell>
                        )
                    )}
                    <TableCell>
                      
                        <Button 
                          variant="contained" 
                          onClick={() => handleOpenRowModal(row)}
                        >
                          Detalhes
                        </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      )}

      {selectedRow && (
        <ModalDetails open={openRowModal} onClose={() => setOpenRowModal(false)} row={selectedRow} reload={loadData} />
      )}
    </Box>
  );
};

export default InfluencerTable;
