import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { influencerColumnNames } from '../utils/constants/';

interface ColumnTogglesProps {
  selectedColumns: Record<string, boolean>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

const ColumnToggles: React.FC<ColumnTogglesProps> = ({ selectedColumns, setSelectedColumns }) => {
  const handleColumnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColumns({
      ...selectedColumns,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div>
      {Object.keys(selectedColumns).map((column) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedColumns[column]}
              onChange={handleColumnChange}
              name={column}
            />
          }
          label={influencerColumnNames[column]}
          key={column}
        />
      ))}
    </div>
  );
};

export default ColumnToggles;
